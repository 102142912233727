<template>
  <Layout>
    <img
      :src="flowsheet"
      alt="流程图"
      class="flowsheet"
    >
  </Layout>
</template>

<script>
export default {
  name: 'Flowsheet',
  data () {
    return {
      flowsheet: require('@/assets/img/flowsheet.png')
    }
  }
}
</script>

<style lang="less">
  .flowsheet {
    width: 100%;
  }
</style>
